require('../scss/style.scss');

window.jQuery = window.$ = $ = jQuery = require("jquery");

require('popper.js');
require('bootstrap');

import AOS from 'aos'

import isMobile from 'ismobilejs';

import gsap from  './lib/gsap.min';

import EmblaCarousel from 'embla-carousel'

import lax from 'lax.js'

const Swal = require('sweetalert2');

var newW,
newH;
var footerReached = false;

var windowParams = {
	w: window,
	d: document,
	e: function() {
		return this.d.documentElement;
	},
	g: function() {
		return this.d.getElementsByTagName('body')[0];
	},
	x: function() {
		return this.w.innerWidth||this.e().clientWidth||this.g().clientWidth;
	},
	y: function() {
		return this.w.innerHeight||this.e().clientHeight||this.g().clientHeight;
	}
};

var loader = {
	loaderTxt: document.getElementById("loader_text"),
	canvasBg: document.getElementById("loader_canvas"),
	contextBg: false,
	loaderAnimation: false,
	loaderTop: {x: 0},
	loaderBottom: {x: 0},
	loaderBg: '#FFC901',
	// Init Loader
	init: function() {
		newH = windowParams.y();
		newW = windowParams.x();
		
		this.canvasBg = document.getElementById("loader_canvas");
		this.contextBg = this.canvasBg.getContext("2d");
		
		this.canvasBg.setAttribute("width", newW);
		this.canvasBg.setAttribute("height", newH);
		
		newH = windowParams.y();
		newW = windowParams.x();
		
		this.canvasBg.width = this.canvasBg.width;
		this.canvasBg.height = this.canvasBg.height;
		this.contextBg.fillStyle = this.loaderBg;
		this.contextBg.beginPath();
		this.contextBg.moveTo(newW, 0);
		this.contextBg.lineTo(this.loaderBottom.x, 0);
		this.contextBg.lineTo(this.loaderTop.x, newH);
		this.contextBg.lineTo(newW, newH);
		this.contextBg.closePath();
		this.contextBg.fill();
	},
	closeLoader: function() {
		this.loaderAnimation = new TimelineMax()
		.fromTo(this.loaderTop, 1.3,
			{ x: windowParams.x()},
			{ x: -10, ease: Quint.easeInOut,
				onUpdate: $.proxy(this.drawCanvasClose, this)
			},'endCloseLoaderCallback')
		.fromTo(this.loaderBottom, 1,
			{ x: windowParams.x()},
			{ x: -50, ease: Quint.easeInOut,
				onComplete: $.proxy(this.endCloseLoaderCallback, this)
			},
		'endCloseLoaderCallback');
			
		setTimeout(function(){

			$('body').addClass('init');

			TweenMax.set( $('#blur feGaussianBlur'), {attr: {stdDeviation:'20,0'}});
			TweenMax.fromTo( "#loader_text", 1.2, { x:'0'},{ x:'-999em' , ease:Quint.easeOut, delay:0});
		}, 450);
			
		setTimeout(function(){
			TweenMax.fromTo( ".website-wrapper-inner", 1, { x:'200px'},{ x:'0', ease:Quint.easeInOut});
			TweenMax.fromTo( ".website-wrapper-inner", 1, { opacity:0},{ opacity:1, ease:Quint.easeInOut});
			var taskTl = new TimelineLite;
			
			taskTl.staggerTo('#website_wrapper .welcome-box span.heading', .6,
			  {y:0, opacity: 1, ease:Quint.easeOut, delay: 0.5}, .12, null);
			
		}, 200);	
	},
	drawCanvasClose: function(obj) {
		newH = windowParams.y();
		newW = windowParams.x();
		
		this.canvasBg.width = this.canvasBg.width;
		this.canvasBg.height = this.canvasBg.height;
		this.contextBg.fillStyle = this.loaderBg;
		this.contextBg.beginPath();
		this.contextBg.moveTo(0, 0);
		this.contextBg.lineTo(this.loaderTop.x, 0);
		this.contextBg.lineTo(this.loaderBottom.x, newH);
		this.contextBg.lineTo(0, newH);
		this.contextBg.closePath();
		this.contextBg.fill();
		
		setTimeout(function(){
			$('.bg-loading').hide();
		}, 450);
	},
	endCloseLoaderCallback: function() {
		$('body').removeClass('init');
		$('body').addClass('loaded');
		$('#loader_container').remove();
	}
};

var scrollAnimate = {
	// Init
	init: function() {
		lax.init();
		// Add a driver that we use to control our animations
		lax.addDriver('scrollY', function () {
			return window.scrollY
		});

		setTimeout(function(){
			// if (!isMobile().any) {
				scrollAnimate.animateIllustration();
				scrollAnimate.animateIntroBlueOne();
				scrollAnimate.animateIntroBlueTwo();
			// }
			scrollAnimate.animateIntroLineOne();
			scrollAnimate.animateIntroLineTwo();
		}, 2000);

		// if (!isMobile().any && newH > 579) {
		if (newH > 579 && newW > 329) {
			scrollAnimate.animateFaceSplit();
		}
		
	},
	animateIllustration: function() {
		lax.addElements('img.illustration', {
			scrollY: {
				translateY: [
					['elCenterY+400', 'elOutY+400'],
					[0, 200],
					{
						inertia: 50
					}
				]
			}
		});
	},
	animateIntroLineOne: function() {
		var diviTop = newH/4;
		lax.addElements('.anim-op-first', {
			scrollY: {
				opacity: [
					['elCenterY', 'elOutY-'+diviTop],
					[1, 0],
					{
						inertia: 10
					}
				],
			}
		});
	},
	removeIntroLineOne: function() {
		lax.removeElements('.anim-op-first');
	},
	animateIntroLineTwo: function() {
		var diviTop = newH/7;

		lax.addElements('.anim-op:not(.anim-op-first)', {
			scrollY: {
				opacity: [
					['elCenterY-'+diviTop, 'elOutY-'+diviTop],
					[1, 0],
					{
						inertia: 10
					}
				],
			}
		})
	},
	removeIntroLineTwo: function() {
		lax.removeElements('.anim-op:not(.anim-op-first)');
	},
	animateIntroBlueOne: function() {
		var diviTop = newH/4;

		if(newH > 800) {
			var diviTopHalf = newH/10;
		}
		else {
			if(newH < 500) {
				var diviTopHalf = newH/2;
			}
			else {
				var diviTopHalf = newH/6;
			}
		}
		
		var diviTopEnd = 260;
		if(newW <= 329) {
			diviTopEnd = 200;
		}

		lax.addElements('.op-one', {
			scrollY: {
				translateY: [
					['elCenterY+'+diviTop, 'elOutY+'+diviTopHalf],
					[0, diviTopEnd]
				]
			}
		})
	},
	removeIntroBlueOne: function() {
		lax.removeElements('.op-one');
	},
	animateIntroBlueTwo: function() {
		var diviTop = newH/5;

		var diviTopEnd = 180;
		if(newW <= 329) {
			diviTopEnd = 140;
		}

		lax.addElements('.op-two', {
			scrollY: {
				translateY: [
					['elCenterY+'+diviTop, 'elOutY+0'],
					[0, diviTopEnd]
				]
			}
		})
	},
	removeIntroBlueTwo: function() {
		lax.removeElements('.op-two');
	},
	animateFaceSplit: function() {
		var faceSplitW = newW / 2;

		var faceSplitH = $('.face-split').height() - 27;

		var faceSplitRatio1 = newH / 1.8;
		var faceSplitRatio2 = newH / 2.1;
		var faceSplitRatio3 = newH / 1.7;

		lax.addElements('.face-split .left', {
			scrollY: {
				translateX: [
					['elInY+'+faceSplitRatio1, 'elOutY-'+faceSplitRatio2],
					[0, "-"+faceSplitW],
					{
						inertia: 10,
						easing: 'easeOutCirc'
					}
				],
				"box-shadow": [
					['elInY+'+faceSplitRatio1, 'elInY+'+faceSplitRatio3],
					[0, 0.72],
					{
						cssFn: (val) => {
							return `5px 0px 15px -8px rgba(0,0,0,${val})`;
						}
					}
				]
			}
		})
		lax.addElements('.face-split .right', {
			scrollY: {
				translateX: [
					['elInY+'+faceSplitRatio1, 'elOutY-'+faceSplitRatio2],
					[0, faceSplitW],
					{
						inertia: 10,
						easing: 'easeOutCirc'
					}
				],
				"box-shadow": [
					['elInY+'+faceSplitRatio1, 'elInY+'+faceSplitRatio3],
					[0, 0.72],
					{
						cssFn: (val) => {
							return `-5px 0px 15px -8px rgba(0,0,0,${val})`;
						}
					}
				]
			}
		})

		var faceSplitRatio4 = newH / 4.2;

		lax.addElements('#cases', {
			scrollY: {
				opacity: [
					['elOutY-'+faceSplitRatio4, 'elOutY-100'],
					[1, 0]
				]
			}
		})

		var faceSplitRatio5 = newH / 2.5;
		var faceSplitRatio6 = newH / 1.6;
		var faceSplitRatio7 = newH / 1.1;
		
		lax.addElements('.face-split .text-content', {
			scrollY: {
				opacity: [
					['elInY+'+faceSplitRatio5, 'elInY+'+faceSplitRatio6],
					[0.8, 1]
				],
				translateY: [
					['elInY+'+faceSplitRatio5, 'elInY+'+faceSplitRatio6, 'elInY+'+faceSplitRatio7],
					[0, -30, 0]
				],
			}
		})

		lax.addElements('.face-split .text-content h3', {
			scrollY: {
				scale: [
					['elInY+'+faceSplitRatio5, 'elInY+'+faceSplitRatio6, 'elInY+'+faceSplitRatio7],
					[0.5, 0.8, 1]
				]
			}
		})

		var faceSplitRatio8 = newH / 2.1;

		lax.addElements('.face-split .text-content a', {
			scrollY: {
				opacity: [
					['elInY+'+faceSplitRatio8, 'elInY+'+faceSplitRatio6],
					[0, 1]
				],
				translateY: [
					['elInY+'+faceSplitRatio8, 'elInY+'+faceSplitRatio6],
					[50, 0]
				],
			}
		}, {
			onUpdate: function (driverValues, domElement) {
				if(footerReached) {
					if($('body').hasClass('contact-open')) {
						$('.morph-button.scr-down').css('opacity', 1);
					}
					else {
						const scrollY = driverValues.scrollY[0];
						$('.morph-button.scr-down').css('opacity', $(domElement).css('opacity'));
					}
			  		
				}
			}
		  })
	

		var faceSplitRatio9 = newH / 4.2;
		var faceSplitRatio10 = newH / 3.3;

		if(newH <= 1280) {
			faceSplitRatio9 = faceSplitRatio9 - 50;
			if(newH <= 900) {
				faceSplitRatio9 = faceSplitRatio9 - 100;
				if(newH < 850) {
					faceSplitRatio10 = faceSplitRatio10 - 150;
				}
			}
		}

		lax.addElements('.contact-content .mail', {
			scrollY: {
				opacity: [
					['elInY+'+faceSplitRatio9, 'elInY+'+faceSplitRatio10],
					[0, 1]
				],
				translateY: [
					['elInY+'+faceSplitRatio9, 'elInY+'+faceSplitRatio10],
					[50, 0]
				],
			}
		})


		// if(newH <= 1280) {
		// 	faceSplitRatio9 = faceSplitRatio9 - 50;
		// 	if(newH <= 900) {
		// 		faceSplitRatio9 = faceSplitRatio9 - 100;
		// 		if(newH < 850) {
		// 			faceSplitRatio10 = faceSplitRatio10 - 150;
		// 		}
		// 	}
		// }
		lax.addElements('.contact-content .available', {
			scrollY: {
				opacity: [
					['elInY+'+faceSplitRatio9, 'elInY+'+faceSplitRatio10+10],
					[0, 1]
				],
				translateY: [
					['elInY+'+faceSplitRatio9, 'elInY+'+faceSplitRatio10+10],
					[50, 0]
				],
			}
		})
	},
	removeAnimateFaceSplit: function() {

		$('.face-split *, .contact-content *, #cases').attr('style', function(i, style) {
			return style && style.replace(/transform[^;]+;?|opacity[^;]+;?|box-shadow[^;]+;?/g, '');
		});

		lax.removeElements('.face-split .left');
		lax.removeElements('.face-split .right');
		lax.removeElements('#cases');
		lax.removeElements('.face-split .text-content');
		lax.removeElements('.face-split .text-content a');
		lax.removeElements('.contact-content .mail');
		lax.removeElements('.contact-content .available');
	},
};
	
document.addEventListener("DOMContentLoaded", function() {
	newH = windowParams.y();
	newW = windowParams.x();
	

	// DOM LOADED INIT
	setTimeout(function(){

		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'instant'
		});

		loader.init();
	}, 200);
	
	setTimeout(function(){
		loader.closeLoader();
		scrollAnimate.init();
	}, 1200);
	
	AOS.init();
	
	
	
});

if(typeof window.performance != 'undefined') {
	var dataTypeEntries = window.performance.getEntriesByType("navigation");
	if(typeof dataTypeEntries != 'undefined' && typeof dataTypeEntries[0] != 'undefined') {
		var dataTypeExec = dataTypeEntries[0].type;
		if(dataTypeExec == 'reload') {
			window.scrollTo(0, 0, 5);
		}
	}
}
		
$(document).ready(function(){

	var morphC = $('.morph-content');
	var baseCW = newW > 329 ? 215 : $('.open-form-btn').outerWidth();
	var baseCH = 52;
	// var morphCW = windowParams.x() > 420 ? 420 : 320;
	// var morphCH = 570;

	var boxFormTl = new TimelineLite;
	var btnTrigerScroll = false;
	var fromFooterClick = false;
	
	function openForm(elm) {
		
		var morphCW = newW > 834 ? 805 : (newW - 30);
		var morphCH = newW > 767 ? 340 : $('.content-contact .right-side .infos').height() + 60;
		if(newW <= 329) {
			morphCW = baseCW;
		}
		if(newW <= 589) {
			morphCH = 104 + $('.content-contact .right-side .infos').height() + 60;
		}
		if(newW <= 509) {
			morphCH = 172 + $('.content-contact .right-side .infos').height() + 30;
		}

		var leftCalc = (elm.offset().left *2);
		var wOffsetLeft = $(window).width() - leftCalc;
		wOffsetLeft = ((wOffsetLeft - morphCW)/2);

		var regularTop = ($(window).height() - morphCH)/2;
		
		$('body').addClass('contact-open');
		
		var morphCWRatio = morphCW/2;
		var morphCHRatio = morphCH/2;
		
		var xSc = window.innerWidth / 2;
		var ySc = window.innerHeight / 2;
		
		xSc = xSc - morphCWRatio;
		ySc = ySc - morphCHRatio;
		
		var cTop = regularTop - (elm.offset().top - $(window).scrollTop());
		
		elm.fadeOut(0.2);
		TweenMax.fromTo( morphC, 0.75, 
			{ width:morphCW, ease:Quint.easeInOut},
			{ height:morphCH, ease:Quint.easeInOut},
		);
		TweenMax.fromTo( morphC, 0.75,
			 { x:wOffsetLeft, ease:Quint.easeInOut},
			 { y:cTop, ease:Quint.easeInOut}
		);

		TweenMax.to( $('.morph-content .content-contact'), 0.15,
			{ opacity:1, ease:Quint.easeInOut }
	    );

		TweenMax.to( $('.morph-content .content-contact .right-side'), 0.75,
			{ x:0, ease:Quint.easeInOut, delay: 0.4}
	   	);

	   TweenMax.to( $('.morph-content .content-contact .me .inner'), 0.45,
			{ marginLeft:0, ease:Quint.easeInOut, delay: 0.75, onComplete: function() {
				$('.morph-content .content-contact .me').addClass('loaded');
			}}
	   );

	   TweenMax.to( $('.morph-button .morph-content .content-contact .right-side .infos'), 0.45,
			{ x:0, opacity: 1, ease:Quint.easeInOut, delay: 0}
		);

	   boxFormTl.staggerTo('.morph-button .morph-content .content-contact .left-side .infos', .4,
			{y:0, opacity: 1, ease:Quint.easeOut, delay: 0.9}, 
		.05);
	}
		
		
	function closeForm() {
		
		TweenMax.staggerTo( $('.morph-content .content-contact'), .15,
			{ opacity:0, ease:Expo.out }
	    );
		
		setTimeout(function(){
			$('body').removeClass('contact-open');

			TweenMax.to( $('.morph-content .content-contact .right-side'), 0,
				{ x:'100%', ease:Circ.easeInOut}
			);

			TweenMax.to( $('.morph-content .content-contact .me .inner'), 0,
				{ marginLeft:200, ease:Quint.easeInOut, onComplete: function() {
					$('.morph-content .content-contact .me').removeClass('loaded');
				}}
			);

			TweenMax.to( $('.morph-button .morph-content .content-contact .right-side .infos'), 0,
				{ x:-100, opacity: 0, ease:Quint.easeInOut}
			);

			boxFormTl.to('.morph-button .morph-content .content-contact .left-side .infos', 0,
				{y:10, opacity: 0, ease:Quint.easeOut}
			);

			TweenMax.fromTo( morphC, 0.25, 
				{ width:baseCW, ease:Quint.easeInOut, delay: 0.25},
				{ height:baseCH, ease:Quint.easeInOut, delay: 0.25},
			);
			TweenMax.fromTo( morphC, 0.25, 
				{ x:0, ease:Quint.easeInOut, delay: 0.25},
				{ y:0, ease:Quint.easeInOut, delay: 0.25}
			);
		}, 250);

	    
			
		if (btnTrigerScroll) {
			if (!fromFooterClick) {
				if(!footerReached) {
					setTimeout(function(){
						$('.sticky-contact').addClass('on-screen');
					}, 300);
				}
			}
		}
		else {
			fromFooterClick = false;
		}
		setTimeout(function(){
			
			$('.morph-button .btn').fadeIn(250);
			
		}, 750);
	}
	
	$('.btn-vcard').on('click', function() {
		$.ajax({
            type: 'POST',
            url: '/data.php',
            async: true,
            data: {
                "requestVcard": true
            },
            success(data) {},
            error(data) {}
        });
	});
			
	$('.open-form-btn').on('click', function() {
		openForm($(this));
		$.ajax({
            type: 'POST',
            url: '/data.php',
            async: true,
            data: {
                "requestContact": true
            },
            success(data) {},
            error(data) {}
        });

	});
			
	$('.overlay, .morph-content-close').on('click', function() {
		closeForm();
	});
			
			
	$(".floatlabel input, .floatlabel textarea").focus(function() {
		var elementId = $(this).attr('id');
		$("label[for=" + elementId + "]").addClass('floatlabel-shift');
	});
	$(".floatlabel input, .floatlabel textarea").blur(function() {
		if($(this).val().length == 0) {
			var elementId = $(this).attr('id');
			$("label[for=" + elementId + "]").removeClass('floatlabel-shift');
		}
	});
	$(".floatlabel input, .floatlabel textarea").focus(function() {
		var elementId = $(this).attr('id');
		$("label[for=" + elementId + "]").addClass('floatlabel-active');
	});
	$(".floatlabel input, .floatlabel textarea").blur(function() {
		var elementId = $(this).attr('id');
		$("label[for=" + elementId + "]").removeClass('floatlabel-active');
	});
			
	
	const emblaNode = document.querySelector('.embla');
	const options = {
		loop: false,
		startIndex: 1, 
		draggable: false,
		breakpoints: {
			'(max-width: 575px)': { 
				align: 'start',
				slidesToScroll: 1,
			 },
		},
	};

	const embla = EmblaCarousel(emblaNode, options);
	const prevButtonNode = emblaNode.querySelector('.embla__prev');
	const nextButtonNode = emblaNode.querySelector('.embla__next');

	
			
	prevButtonNode.addEventListener('click', function(e) {
		if (embla.selectedScrollSnap() >= 2) {
			embla.scrollPrev();
		}
	}, false)
	nextButtonNode.addEventListener('click', embla.scrollNext, false);

	const disablePrevNextBtns = (prevBtn, nextBtn, embla) => {
		return () => {
		  if (embla.selectedScrollSnap() > 1) prevBtn.removeAttribute('disabled');
		  else prevBtn.setAttribute('disabled', 'disabled');
	  
		  if (embla.canScrollNext()) nextBtn.removeAttribute('disabled');
		  else nextBtn.setAttribute('disabled', 'disabled');
		};
	};

	const disablePrevAndNextBtns = disablePrevNextBtns(prevButtonNode, nextButtonNode, embla);
	embla.on("init", disablePrevAndNextBtns);
	embla.on("select", disablePrevAndNextBtns);

	setTimeout(function(){
			
		var topDistance = $('.morph-button').offset().top;
		var footerTop = $('#cases').offset().top + $('#cases').outerHeight() - 270;
		var eventMorphTrigger = $('.morph-button').offset().top + $('.morph-button').outerHeight();
		var stickyBtnPos;

		$(window).on('scroll', function() {
			var scrollTop = $(this).scrollTop();

			var sticlyBPosition = $('.sticky_btn').offset();

			if ( (topDistance + $('.morph-button').height()) < scrollTop ) {
				if (btnTrigerScroll == false) {
					btnTrigerScroll = true;

					stickyBtnPos = $('#sticky_btn').offset().left;

					$('.sticky-contact').addClass('on-screen');
					$('.morph-button').addClass('scr-down');
					$('.morph-button').css('left', stickyBtnPos);
				}
				$('.morph-button').css('top', scrollTop-52);
			}
			else {
				btnTrigerScroll = false;
				$('.sticky-contact').removeClass('on-screen');
				$('.morph-button').removeClass('scr-down');
				$('.morph-button').css('left', '');
				$('.morph-button').css('top', '');
				$('.morph-button').css('opacity', '');
				if(!$('.morph-button').attr('data-aos')) {
					$('.morph-button').attr('data-aos', "fade-up");
					$('.morph-button').attr('data-aos-delay', "150");
				}
				
				
			}
			
			if ( footerTop < scrollTop ) {
				footerReached = true;
				$('.sticky-contact').removeClass('on-screen');
				$('.morph-button').removeAttr('data-aos');
				$('.morph-button').removeAttr('data-aos-delay');
				$('.morph-button').css('left', sticlyBPosition.left);
				$('.morph-button').css('top', sticlyBPosition.top);
			}
			else {
				$('.morph-button.scr-down').css('opacity', '');
				footerReached = false;
				if (btnTrigerScroll) {
					$('.sticky-contact').addClass('on-screen');
					$('.morph-button').css('left', stickyBtnPos);
				}
			}
		});
		
	}, 1000);

	$(window).on('resize', function() {

		newH = windowParams.y();
		newW = windowParams.x();
		// Remove Scroll Animation & Recreate
		scrollAnimate.removeIntroLineOne();
		scrollAnimate.animateIntroLineOne();

		scrollAnimate.removeIntroLineTwo();
		scrollAnimate.animateIntroLineTwo();

		scrollAnimate.removeIntroBlueOne();
		scrollAnimate.animateIntroBlueOne();

		scrollAnimate.removeIntroBlueTwo();
		scrollAnimate.animateIntroBlueTwo();

		scrollAnimate.removeAnimateFaceSplit();
		if (newH > 579 && newW > 329) {
			scrollAnimate.animateFaceSplit();
		}

		closeForm();
	});
	
			
	$('.sticky_btn, #sticky_btn').on('click', function() {
		$('.morph-button').css('opacity', 1);
		if ($(this).hasClass('from-footer')) {
			fromFooterClick = true;
		}
		openForm($('.open-form-btn'));
		$('.sticky-contact').removeClass('on-screen');
	});
			
});